.page-link{
    min-width: $nk-pagination-min-width;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-weight: 500;
}
.pagination{
    &-sm{
        .page-link{
            min-width: $nk-pagination-min-width-sm;
        }
    }
    &-s1{
        align-items: center;
        .page{
            &-item{
                margin-right: $nk-pagination-s1-item-gap-x;
                &:last-child{
                    margin-right: 0;
                }
                &:not(:first-child) .page-link {
                    margin-left: 0;
                }
                &:first-child .page-link,
                &:last-child .page-link{
                    &:hover{
                        background-color: transparent;
                    }
                }
                &.active .page-link{
                    color: $nk-pagination-s1-active-color;
                    background-color: $nk-pagination-s1-active-bg;
                }
            }
            &-link{
                border-radius: $border-radius;
                border: 0;
                display: flex;
                &:hover{
                    color: $nk-pagination-s1-hover-color;
                    background-color: $nk-pagination-s1-hover-bg;
                }
                .icon{
                    font-size: $nk-pagination-s1-icon-font-size;
                }
            }
        }
    }
}
