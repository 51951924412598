.svgMap-wrapper,
.svgMap-container {
  position: relative;
}

.svgMap-block-zoom-notice {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  opacity: 0;
  color: #fff;
  transition: opacity 250ms;

  .svgMap-block-zoom-notice-active & {
    pointer-events: all;
    top: 0;
    opacity: 1;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 32px;
    transform: translateY(-50%);
    font-size: 28px;

    @media (max-width: 900px) {
      font-size: 22px;
    }
  }
}

.svgMap-map-wrapper {
  position: relative;
  width: 100%;
  padding-top: 50%;
  overflow: hidden;
  color: $textColor;

  * {
    box-sizing: border-box;
  }

  :focus:not(:focus-visible) {
    outline: 0;
  }

  .svgMap-map-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  // Controls

  .svgMap-map-controls-wrapper {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    display: flex;
    overflow: hidden;
    border-radius: 2px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  .svgMap-map-controls-zoom,
  .svgMap-map-controls-move {
    display: flex;
    margin-right: 5px;
    overflow: hidden;
    background: #fff;

    &:last-child {
      margin-right: 0;
    }
  }

  .svgMap-control-button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;

    &.svgMap-zoom-button {
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #666;
        transition: background-color 250ms;
      }

      &:before {
        width: 11px;
        height: 3px;
      }
      &.svgMap-zoom-reset-button::before {
        width: 11px;
        height: 11px;
        background: none;
        border: 2px solid #666;
      }

      @media (hover: hover) {
        &:hover {
          &:before,
          &:after {
            background: $textColor;
          }
        }
      }

      &:active {
        &:before,
        &:after {
          background: $textColor;
        }
      }

      &.svgMap-disabled {
        &:before,
        &:after {
          background: #ccc;
        }
      }
      &.svgMap-zoom-reset-button {
        &.svgMap-disabled:before {
          border: 2px solid #ccc;
          background: none;
        }
      }
    }

    &.svgMap-zoom-in-button {
      margin: 1px 0 1px 1px;

      &:after {
        width: 3px;
        height: 11px;
      }
    }

    &.svgMap-zoom-out-button {
      margin: 1px 1px 1px 0;
    }
  }

  // Contient controls 
  .svgMap-map-continent-controls-wrapper{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    display: flex;
    border-radius: 2px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  // Countries

  .svgMap-country {
    stroke: #fff;
    stroke-width: 1;
    stroke-linejoin: round;
    vector-effect: non-scaling-stroke;
    transition: fill 250ms, stroke 250ms;

    &[data-link] {
      cursor: pointer;
    }

    @media (hover: hover) {
      &:hover {
        stroke: $mapActiveStrokeColor;
        stroke-width: $mapActiveStrokeWidth;
      }
    }

    &.svgMap-active {
      stroke: $mapActiveStrokeColor;
      stroke-width: $mapActiveStrokeWidth;
    }
  }
}
