

.player-wrapper {
    position: relative;
    padding: 0.2%; /* Player ratio: 100 / (1280 / 720) */
}
  
//   .react-player {
//     position: absolute;
//     top: 0;
//     left: 0;
//   }