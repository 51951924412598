.svgMap-tooltip {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  border-radius: 6px;
  background: $white;
  transform: translate(-50%, -100%);
  display: none;
  pointer-events: none;
  min-width: 60px;

  &.svgMap-tooltip-flipped {
    transform: translate(-50%, 0);
    border-bottom: 0;
    border-top: 1px solid $white;
  }

  &.svgMap-active {
    display: block;
  }

  .svgMap-tooltip-content-container {
    position: relative;
    padding: 10px 20px;

    .svgMap-tooltip-flag-container {
      text-align: center;
      margin: 2px 0 5px;

      &.svgMap-tooltip-flag-container-emoji {
        font-size: 50px;
        line-height: 0;
        padding: 25px 0 15px;
      }

      .svgMap-tooltip-flag {
        display: block;
        margin: auto;
        width: auto;
        height: 32px;
        padding: 2px;
        border-radius: 2px;
      }
    }
  }

  .svgMap-tooltip-title {
    white-space: nowrap;
    font-size: 16px;
    line-height: 28px;
    padding: 0 0 8px;
    text-align: center;
    font-weight: 500;
    color: $base-color;
  }

  .svgMap-tooltip-content {
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    color: $textColorLight;
    margin: -5px 0 0;

    table {
      padding: 0;
      border-spacing: 0px;
      margin: auto;

      td {
        padding: 2px 0;
        text-align: left;

        span {
          color: $textColor;
        }

        &:first-child {
          padding-right: 10px;
          text-align: right;
        }

        sup {
          vertical-align: baseline;
          position: relative;
          top: -5px;
        }
      }
    }

    .svgMap-tooltip-no-data {
      padding: 2px 0;
      color: $textColorLight;
      font-style: italic;
    }
  }

  .svgMap-tooltip-pointer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    height: 10px;
    width: 30px;

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      background: $white;
      border: 1px solid $white;
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &.svgMap-tooltip-flipped {
    .svgMap-tooltip-pointer {
      bottom: auto;
      top: -10px;
      transform: translateX(-50%) scaleY(-1);
    }
  }
}
