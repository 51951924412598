@use "sass:math";

$dz-height: 160px;
$dz-gutters:1rem;
$dz-preview-gap:.5rem;

@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  30%, 70% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}


@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% { transform: scale(1); }
  10% { transform: scale(1.1); }
  20% { transform: scale(1); }
}
.dropzone, .dropzone * {
  box-sizing: border-box;
}
.dropzone {

  $image-size: 110px;

  $image-border-radius: $nk-border-radius;

  &.dz-clickable {
    cursor: pointer;

    * {
      cursor: default;
    }
    .dz-message {
      &, * {
        cursor: pointer;
      }
    }
  }

  min-height: $dz-height;
  border:$field-border-width solid $field-border-color;
  border-radius: $field-border-radius;
  padding: $dz-gutters;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: $field-bg;

  &.dz-started {
    .dz-message {
      display: none;
    }
  }

  &.dz-drag-hover {
    border-style: solid;
    .dz-message {
      opacity: 0.5;
    }
  }
  .dz-message {
    text-align: center;
    margin: .5rem 0 .75rem;

    .dz-button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
  }



  .dz-preview {
    position: relative;
    display: inline-block;

    vertical-align: top;

    margin: $dz-preview-gap;
    min-height: $image-size;

    &:hover {
      // Making sure that always the hovered preview element is on top
      z-index: 1000;
      .dz-details {
        opacity: 1;
      }
    }

    &.dz-file-preview {

      .dz-image {
        border-radius: $image-border-radius;
        background: #999;
        background: linear-gradient(to bottom, #eee, #ddd);
      }

      .dz-details {
        opacity: 1;
      }
    }

    &.dz-image-preview {
      background: white;
      .dz-details {
        transition: opacity 0.2s linear;
      }
    }

    .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }

    &:hover .dz-details {
      opacity: 1;
    }
    .dz-details {
      $background-color: $dark;

      z-index: 20;

      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;

      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 1.5rem .75rem;
      text-align: center;
      color: $base-color;

      $width: 120px;

      line-height: 150%;

      .dz-size {
        margin-bottom: 1em;
        font-size: 16px;
        color:$base-dark;
      }

      .dz-filename {
        white-space: nowrap;
        display: inline-flex;
        border-radius: $border-radius-sm;
        span{
          border: 1px solid $border-light;
        }
        &:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
        }

      }

      .dz-filename, .dz-size {
        span {
          background-color: $white;
          padding: .125rem .375rem;
          border-radius: $border-radius-sm;
        }
      }

    }

    &:hover {
      .dz-image {
        // opacity: 0.8;
        img {
          transform: scale(1.05, 1.05); // Getting rid of that white bleed-in
          filter: blur(8px); // Getting rid of that white bleed-in
        }
      }
    }
    .dz-image {
      border-radius: $image-border-radius;
      overflow: hidden;
      width: $image-size;
      height: $image-size;
      position: relative;
      display: block;
      z-index: 10;
      border:$field-border-width solid $field-border-color;

      img {
        display: block;
      }
    }


    &.dz-success {
      .dz-success-mark {
        animation: passing-through 3s cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
    }
    &.dz-error {
      .dz-error-mark {
        opacity: 1;
        animation: slide-in 3s cubic-bezier(0.770, 0.000, 0.175, 1.000);
      }
    }


    $overlay-color: white;
    $overlay-bg-color: rgba(0, 0, 0, 0.8);


    .dz-success-mark, .dz-error-mark {

      $dz-mark-size: 1.125rem;

      pointer-events: none;

      opacity: 0;
      z-index: 500;

      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: .5rem;
      bottom: .5rem;

      font-size: $dz-mark-size*.75;
      line-height: 1;

      width: $dz-mark-size;
      height: $dz-mark-size;
      color: $white;
      border-radius: 50%;
      border: 1px solid transparent;
      &:after{
        font-family: $nio-font-family;
      }
      svg {
        display: none;
      }
    }
    .dz-error-mark{
      background-color: $danger;
      border-color: lighten($danger,15%);
      &:after{
        content: $ni-cross;
      }
    }
    .dz-success-mark{
      background-color: $success;
      border-color: lighten($success,15%);
      &:after{
        content: $ni-check;
      }
    }
    
      
    &.dz-processing .dz-progress {
      opacity: 1;
      transition: all 0.2s linear;
    }
    &.dz-complete .dz-progress {
      opacity: 0;
      transition: opacity 0.4s ease-in;
    }

    &:not(.dz-processing) {
      .dz-progress {
        animation: pulse 6s ease infinite;
      }
    }
    .dz-progress {
      $progress-height: 20px;
      $progress-border-width: 3px;

      opacity: 1;
      z-index: 1000;

      pointer-events: none;
      position: absolute;
      height: 20px;
      top: 50%;
      margin-top: -10px;
      left: 15%;
      right: 15%;

      border: $progress-border-width solid $overlay-bg-color;
      background: $overlay-bg-color;

      border-radius: 10px;

      overflow: hidden;

      .dz-upload {
        background: $overlay-color;

        display: block;
        position: relative;
        height: 100%;
        width: 0;
        transition: width 300ms ease-in-out;

        border-radius: $progress-height - $progress-border-width;
      }

    }

    &.dz-error {
      .dz-error-message {
        display: block;
      }
      &:hover .dz-error-message {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .dz-error-message {
      $width: 220px;
      $color: $danger;

      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      display: none;
      opacity: 0;
      transition: opacity 0.3s ease;
      border-radius: 8px;
      font-size: 13px;
      top: $image-size + 10px;
      left: 50%;
      transform: translateX(-$width*.5);
      width: $width;
      background: $color;
      padding: 0.5em 1em;
      color: white;

      // The triangle pointing up
      &:after {
        content: '';
        position: absolute;
        top: -6px;
        left: math.div($width, 2) - 6px;
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $color;
      }
    }

  }
  .dz-message{
    color: $base-text;
    &-icon{
      color: $base-light;
    }
    &-text{
      small{
        font-size: 70%;
        color: $base-light;
        margin-left: 0.25rem;
      }
    }
  }
}


