@use "sass:math";
/* Functional styling; * These styles are required for noUiSlider to function. * You don't need to change these rules to apply your design.*/
$base-size: .675rem;
$handle-size: 1.75rem;
$handle-line-size: .75rem;

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
/* Offset direction
 */
// .noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
//   left: 0;
//   right: auto;
// }
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: $base-size;
}
.noUi-horizontal .noUi-handle {
  width: $handle-size;
  height: $handle-size;
  right: -$handle-size*.5;
  top: - math.div($handle-size - $base-size,2);
}
.noUi-vertical {
  width: $base-size;
  height: 200px;
}
.noUi-vertical .noUi-handle {
  width: $handle-size;
  height: $handle-size;
  right: - math.div($handle-size - $base-size,2);
  bottom: -$handle-size*.5;
}
// .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
//   left: -$handle-size*.5;
//   right: auto;
// }
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: $light;
  border-radius: $nk-border-radius-pill;
}
.noUi-connects {
  border-radius: $nk-border-radius-pill;
}
.noUi-connect {
  background: $accent-color;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid $border-color;
  border-radius: $nk-border-radius-pill;
  background: $white;
  cursor: default;
  box-shadow: $shadow;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: $handle-line-size;
  width: 1px;
  background: $light;
  left: pxToRem(15px);
  top: math.div($handle-size - $handle-line-size - pxToRem(1px),2);
}
.noUi-handle:after {
  left: auto;
  right: pxToRem(15px);
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: $handle-line-size;
  height: 1px;
  left: math.div($handle-size - $handle-line-size - pxToRem(1px),2);
  top: pxToRem(15px);
}
.noUi-vertical .noUi-handle:after {
  top: auto;
  bottom: pxToRem(15px);
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: $lighter;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
// .noUi-rtl .noUi-value-horizontal {
//   -webkit-transform: translate(50%, 50%);
//   transform: translate(50%, 50%);
// }
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
// .noUi-rtl .noUi-value-vertical {
//   -webkit-transform: translate(0, 50%);
//   transform: translate(0, 50%);
// }
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid $border-color;
  border-radius: $nk-tooltip-border-radius;
  background: $nk-tooltip-bg;
  color: $nk-tooltip-color;
  padding: $nk-tooltip-padding-y $nk-tooltip-padding-x;
  text-align: center;
  white-space: nowrap;
  font-size: $nk-tooltip-font-size;
  opacity: 0;
  transition: .3s ease;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}


.noUi-active,.noUi-handle:hover{
  .noUi-tooltip {
    opacity: 1;
  }
}