$media-border: 1px;
$media-normal-radius:.25rem;
$media-rounded-radius: $media-normal-radius * 2;
$media-circle-radius: $border-radius-pill;
$media-font-size:1rem;
$media-negative-start-gap: 0.5rem;

@import "components/text";
@import "components/dropdown";
@import "components/quick-nav";
@import "components/block";
@import "components/tooltip";
@import "components/media";
@import "components/tables";
@import "components/icon";
@import "components/change";
@import "components/amount";
@import "components/chart";
@import "components/dot";
@import "components/timeline";
@import "components/list";
@import "components/schedule";
@import "components/box";
@import "components/user";
@import "components/mask";
@import "components/search";
@import "components/rating";
@import "components/image-upload";
@import "components/pagination";
@import "components/kanban";
@import "components/overlay";
