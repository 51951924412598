.card{
    box-shadow: $nk-card-box-shadow;
    &-gutter{
        &-md{
            --bs-card-spacer-y: 1.625rem;
            --bs-card-spacer-x: 1.625rem;
        }
        &-lg{
            --bs-card-spacer-y: 2.5rem;
            --bs-card-spacer-x: 2.5rem;
        }
    }
    &-title{
        .icon + *{
            margin-left: .5rem;
        }
        &-group{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .card-title{
                --bs-card-title-spacer-y:0;
            }
        }
    }
    &-row{
        display: flex;
        flex-direction: column;
    }
    .card{
        --bs-card-border-radius: .375rem;
    }
    &-auth{
        &-mh{
            display: flex;
            flex-direction: column;
            min-height: 684px;
            > *{
                flex-grow: 1;
                height: 100%;
            }
        }
    }
}
$card-row-points: lg,xl,xxl;
@each $name in $card-row-points {
    @include media-breakpoint-up(#{$name}){
        .card-row-#{$name}{
            flex-direction: row;
            .card-aside{
                width: 370px;
                flex-shrink: 0;
            }
        }
    }
}
