[data-overlay]{
    z-index: 500;
}
.nk-overlay{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba($dark,.5);
    z-index: 499;
    animation: overlay-fade-in .3s 1;
}