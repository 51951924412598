$font_url: '../../../fonts/';

@font-face {
    font-family: 'Manrope';
    src: local('Manrope Bold'), local('Manrope-Bold'),
        url('#{$font_url}Manrope-Bold.woff') format('woff'),
        url('#{$font_url}Manrope-Bold.ttf') format('truetype'),
        url('#{$font_url}Manrope-Bold.svg') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: local('Manrope Medium'), local('Manrope-Medium'),
        url('#{$font_url}Manrope-Medium.woff') format('woff'),
        url('#{$font_url}Manrope-Medium.ttf') format('truetype'),
        url('#{$font_url}Manrope-Medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: local('Manrope SemiBold'), local('Manrope-SemiBold'),
        url('#{$font_url}Manrope-SemiBold.woff') format('woff'),
        url('#{$font_url}Manrope-SemiBold.ttf') format('truetype'),
        url('#{$font_url}Manrope-SemiBold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope';
    src: local('Manrope Regular'), local('Manrope-Regular'),
        url('#{$font_url}Manrope-Regular.woff') format('woff'),
        url('#{$font_url}Manrope-Regular.ttf') format('truetype'),
        url('#{$font_url}Manrope-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// Roboto
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('#{$font_url}Roboto-Medium.woff') format('woff'),
        url('#{$font_url}Roboto-Medium.ttf') format('truetype'),
        url('#{$font_url}Roboto-Medium.svg') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'),
        url('#{$font_url}Roboto-Regular.woff') format('woff'),
        url('#{$font_url}Roboto-Regular.ttf') format('truetype'),
        url('#{$font_url}Roboto-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('#{$font_url}Roboto-Bold.woff') format('woff'),
        url('#{$font_url}Roboto-Bold.ttf') format('truetype'),
        url('#{$font_url}Roboto-Bold.svg') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
