$mask-1-height: 200px;
.mask{
     position: absolute;
    &-1{
        top: 0;
        left: 0;
        width: 504px;
        height: $mask-1-height;
        background-image: url("../../../images/mask/a.png");
        background-size: cover;
    }
    &-2{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../../images/mask/b.png");
        background-size: cover;
        z-index: -1;
    }
}
.has-mask{
    position: relative;
    &-1{
        padding-top: $mask-1-height;
    }
}