$copyClass : 'js-copy';
.#{$copyClass}{
    background-color: transparent;
    border: 0;
    &-wrap{
        position: relative;
    }
    &-tooltip{
        background-color: $base-dark;
        color: $white;
        text-align: center;
        border-radius: 0.375rem;
        padding: 0.125rem 0.625rem;
        position: absolute;
        z-index: 1;
        top: -100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.75rem;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
        margin: 0 !important;
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            right: 50%;
            margin-right: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $base-dark transparent transparent transparent;
        }
    }
    &:hover{
        .#{$copyClass}-tooltip{
            visibility: visible;
            opacity: 1;
        }
    }
}